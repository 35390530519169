import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Navbar, Nav,  Image, Container } from 'react-bootstrap';
import ProfileIcon from '../images/profile-icon.svg';
import { URL, IMG_URL } from '../config';
import NotificationBell from './Notifications/NotificationBell';
import LogoFont from '../images/logo_font.svg';
import { UserState } from './Recoil/atoms';
import { useRecoilState } from 'recoil';
import { Home, Search, SquarePlus, CircleUserRound, Earth } from 'lucide-react';


const MainNav = ({ notifications }) => {
    // const history = useHistory();
    const location = useLocation();
    const [user, setUser] = useRecoilState(UserState);

    const highlightLink = (location, link) => {
        let header = location.pathname.split('/');
        header = '/' + header[1];
        if (link === header) {
            // console.log('current Link: ', link);
            return "600";
        } else {
            return "300";
        }
    }

    const getProfilePic = () => {
        if (!!user.profile_image) {
            return `url(${IMG_URL}/${user.profile_image.filename})`;
        } else {
            return `url(${ProfileIcon})`;
        }
    };

    const navLinkColor = (link) => {
        let header = location.pathname.split('/');
        header = '/' + header[1];

        if (link === header) {
            // return "#15084D";
            return "black";
        } else {
            return "dimgray";
        }
    }

    const getProfileBorder = () => {
        let header = location.pathname.split('/');
        header = '/' + header[1];

        if ('/profile' === header) {
            return "2px solid #15084D";
        } else {
            return "1px solid #15084D";
        }
    }

    return (
        <>
        <Navbar
            // onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
            // className="ml-3 mt-3 mr-2 mb-2"
            className="nav-font m-0 bg-white main-nav"
            // sticky="top"
        >
                <Nav style={{ width: "100%", maxWidth: "100%"}}>
                <Navbar.Brand as={Link} to="/feed" className="pb-2 pl-2 pl-1 mr-3 text-center mobile-hide">
                    <Image style={{ height: "1.5em" }} src={LogoFont} alt="Trottr Logo" />
                </Navbar.Brand>
                    <Nav.Link className="ml-1 pb-0 mr-4 text-center" as={Link} to="/feed" >
                        <Home color={navLinkColor('/feed')} size={35} />
                        {/* <p className='nav-text mb-0' style={{ fontWeight: highlightLink(location, "/feed")}}>Home</p> */}
                    </Nav.Link>
                    <Nav.Link className="pb-0 mr-4 text-center" as={Link} to="/search/albums" >
                        <Earth color={navLinkColor('/search')} size={35} />
                        {/* <p className='nav-text mb-0' style={{ fontWeight: highlightLink(location, "/search") }}>Search</p> */}
                    </Nav.Link>
                    <Nav.Link className="pb-0 mr-4 text-center" as={Link} to="/post" >
                        <SquarePlus color={navLinkColor('/post')} size={35} />
                        {/* <p className='nav-text mb-0' style={{ fontWeight: highlightLink(location, "/post")}}>Create</p> */}
                    </Nav.Link>
                    <Nav.Link className='mr-4 pb-0 ml-auto text-center' style={{ right: "0" }} as={Link} to="/notifications" >
                        <NotificationBell notifications={notifications} navLinkColor={navLinkColor} />
                    </Nav.Link>
                    <Nav.Link className="ml-0 mr-1 pb-0 text-center" as={Link} to="/profile/share"  >
                        {!!user.profile_image ? (
                            <div className='nav-profile-img' style={{ backgroundImage: getProfilePic(), border: getProfileBorder()}}>
                                <Image className='profile-image'  src={`${IMG_URL}/${user.profile_image?.path}`} />
                            </div>
                        ) : (
                            <CircleUserRound color={navLinkColor('/profile')} size={35} />
                        )}
                        {/* <p className='nav-text mb-0' style={{ fontWeight: highlightLink(location, "/profile")}}>Profile</p> */}
                    </Nav.Link>
                </Nav>
        </Navbar>
        </>
    );
};

export default MainNav;

const navItem={
    display: "inline-block",
    padding: "8px 15px",
    textDecoration: "none",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
};