import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { URL } from '../../config';
import { Row, Col, Container } from 'react-bootstrap';
import ReactGA from 'react-ga4';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { getFolders, getBlogs } from '../apiHelpers';
import DefaultImg from '../../images/trottr-trip-background.png';
import { GOOG_API_KEY } from '../../config';
import Profile from '../Profile/Profile';
import { useRecoilState } from 'recoil';
import { UserState } from '../Recoil/atoms';
import BlogCard from '../Blogs/BlogCard';

const UserView = () => {
    const [folders, setFolders] = useState([]);
    // const authorized = document.cookie.includes('user') || false;
    const [followedUser, setFollowedUser] = useState(undefined);
    const navigate = useNavigate();
    const { username } = useParams();
    const [showFullOverview, setShowFullOverview] = useState(false);
    const [currentFolder, setCurrentFolder] = useState({});
    const location = useLocation();
    const [user, setUser] = useRecoilState(UserState);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // console.log(location.pathname);
        ReactGA.send({ hitType: "pageview", page: "/username"});
        getProfile(username);
    }, [username, location]);

    

    const getProfile = async (username) => {
        setLoading(true);
        const res = await axios.get(`${URL}/users/find/${username}`);
        const followedUser = await res.data[0];
        setFollowedUser(followedUser);
        // console.log('followed user: ', followedUser);
        setTimeout(() => setLoading(false),500);
        if (!!followedUser) {
            getBlogs(followedUser, setFolders);
        }
    }

    useEffect(() => {
        getProfile(username)
    }, [username])

    const openFolder = (folder, e) => {
        setCurrentFolder(folder)
        ReactGA.event({
            category: "Follow",
            action: "User Opens Another User's Trip"
        })
    };

    const toggleOverview = () => {
        setShowFullOverview(showOverview => !showOverview);
    };

    const backToFollow = () => {
        navigate('/follow');
    };

    // const getCurrentFolderPic = (picArray) => {
    //     const index = Math.floor(Math.random() * picArray.length);
    //     const item = picArray[index];
    //     console.log(`url(https://maps.googleapis.com/maps/api/place/photo?maxwidth=1600&photo_reference=${item.photos[0].photo_reference}&key=${GOOG_API_KEY})`);
    //     return `url(https://maps.googleapis.com/maps/api/place/photo?maxwidth=1600&photo_reference=${item.photos[0].photo_reference}&key=${GOOG_API_KEY})`
    // };

    const defaultImage = !!followedUser && !!followedUser.trip_background ? `url(${URL}/${followedUser.trip_background.filename})` : `url(${DefaultImg})`;

    const getCurrentFolderPic = (currentFolder) => {
        if (currentFolder.background_image) {
            return `url(${URL}/${currentFolder.background_image.filename})`;
        } else if (currentFolder.contents) {
            const picArray = currentFolder.contents;
            const index = Math.floor(Math.random() * picArray.length);
            const item = picArray[index];
            // console.log('current trip pic array: ', picArray)
            if (!!item) {
                return `url(https://maps.googleapis.com/maps/api/place/photo?maxwidth=1600&photo_reference=${item.photos[0].photo_reference}&key=${GOOG_API_KEY})`;
            } else {
                return DefaultImg;
            }
        } else if (!!currentFolder.name) {
            return `url(${DefaultImg})`;
        } else {
            return defaultImage;
        }
    }

    const followViewAble = () => {
        if (!followedUser.private || (!!user.following && user.following[followedUser.id]) || followedUser.id === user.id) {
            return true;
        } else {
            return false;
        }
    }

    const isBlogs = () => {
        let isBlogs = false;
        for (let folder of folders) {
            if (folder.blog_view === true) {
                isBlogs = true;
            }
        }
        return isBlogs;
    }


    return (
        <div className="main">
            {!!loading && (
                <Row className='mr-0 ml-0 mt-5 p-0'>
                    {/* <Col className='col-md-2 offset-md-5 text-center'>
                        <Image className='w-50' src={Loading} />
                    </Col> */}
                </Row>
            )}
            {!followedUser && !loading && <h5 className='mt-5'>404. No User Found.</h5>}
            {!!followedUser && !loading && (
                <>
                <Profile user={followedUser} setUser={setFollowedUser} followView={true} />
                {followViewAble() ? (
                    <Container>
                        <Row className="mb-3">
                                {!!folders && folders.map((folder, i) => {
                                    return (
                                        <Col className='col-md-6 col-12 pr-1 pl-1' key={i}>
                                            <BlogCard folder={folder} setCurrentFolder={setCurrentFolder} currentFolder={currentFolder} />
                                        </Col>
                                    )
                                })}
                        </Row>
                    </Container>
                ) : (
                    <h5 className='mt-3 mb-5'>This users profile is private. Request to follow them to view their albums.</h5>
                )}
            </>
            )}
        </div>
    );
};

export default UserView;