import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { Input } from 'react-rainbow-components';
import { URL } from '../../config';
import axios from 'axios';
import { Search } from 'lucide-react';
import BlogCard from '../Blogs/BlogCard';
import { useCookies } from 'react-cookie';


const SearchBlogs = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [blogs, setBlogs] = useState([]);
    const [cookies, setCookies, removeCookie] = useCookies(['blogTerm']);
    const [noResults, setNoResults] = useState(false);
    const [showFeaturedBlogs, setShowFeaturedBlogs] = useState(false);

    const searchBlogs = async (e) => {
        setNoResults(false);
        setShowFeaturedBlogs(false);
        e.preventDefault();

        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 1);
        setCookies('blogTerm', searchTerm, { path: '/', expires: expirationDate });

        const url = `${URL}/blogs/search/location`;
        const res = await axios.get(url, { params: { location: searchTerm }});

        setSearchTerm('');
        if (res.data.length === 0) {
            setNoResults(true);
        }

        setBlogs(res.data);
    };

    const getBlogsFromCookie = async () => {
        const url = `${URL}/blogs/search/location`;
        const res = await axios.get(url, { params: { location: cookies.blogTerm }});
        console.log('res data: ', res);
        setBlogs(res.data);
    };

    const getRecentBlogs = async () => {
        const url = `${URL}/blogs/recent`;
        const res = await axios.get(url);
        setBlogs(res.data);
    }

    useEffect(() => {
        if (!!cookies.blogTerm) {
            getBlogsFromCookie();
        } else {
            setShowFeaturedBlogs(true);
            getRecentBlogs();
        }
    },[])

    return (
        <div className="m-0 p-0">
        <Container className='mb-2'>
            <Row>
                <Col className='col-md-8 offset-md-2'>
                <Form onSubmit={searchBlogs}>
                    <Row>
                        <Col>
                            <Input
                                className='mb-1 rainbow-input w-100'
                                placeholder='Search a destination'
                                icon={<Search color='gray' />}
                                value={searchTerm}
                                onChange={e => setSearchTerm(e.target.value)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className='text-center mt-1'>
                            <Button size="md" className="m-0 rounded-pill btn-primary" variant="primary" type="submit" onSubmit={searchBlogs}>
                                    Search
                            </Button>
                        </Col>
                    </Row>
                </Form>
                <Row className="mb-3">
                    <Col className='pl-0 pr-0'>
                        {!!showFeaturedBlogs && <h4 className='mt-3'>Featured Albums</h4>}
                        {!!noResults && <h6 className='text-secondary mt-3'>Your search did not return any results. Try searching a different location.</h6>}
                        {!!blogs && blogs.map((folder, i) => {
                            return (
                                <div key={i}>
                                    <BlogCard folder={folder} />
                                </div>
                            )
                        })}
                    </Col>
                </Row>
                </Col>
            </Row>
        </Container>
        </div>
    );
};

export default SearchBlogs;