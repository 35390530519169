import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Row, Col, Button, Container } from 'react-bootstrap';
import { useRecoilState } from 'recoil';
import { UserState } from '../Recoil/atoms';
import FollowRequest from './FollowRequest';

const Notifications = ({ setNotifications }) => {
    const [user, setUser] = useRecoilState(UserState);
    const pendingFollowers = !!user.pending_followers ?  Object.keys(user.pending_followers).map(userId => [userId, user.pending_followers[userId]]) : [];

    return (
        <div className='main'>
            <Container>
                <Row>
                    <Col className='text-center'>
                        <h3>Notifications</h3>
                    </Col>
                </Row>
                <Row>
                    <Col className='col-md-6 offset-md-3 border-bottom'>
                        {!!pendingFollowers && pendingFollowers.length > 0 ? pendingFollowers.map(follower => (
                            <FollowRequest follower={follower} setNotifications={setNotifications} />
                        )) : (
                            <h6 className='text-secondary mt-5 mb-5'>You currently have no notifications. Please check back later.</h6>
                        )}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Notifications;